.page-404-wrapper{
    margin: 0 auto !important;
    text-align: center;
    padding:30px;
}
.header{
    font-size: 56px;
    font-weight: 800;
}

@media (max-width:425px) {
    .header{
        font-size: 16px;
        font-weight: 800;
    }
    .description{
        font-size: 9px;
    }
}