@import 'antd/dist/antd.css';

.orange{
    color: darkorange;
}
.b-400{
    font-weight: 400;
}
.m-b-150{
    margin-bottom:150px;
}
.m-b-80{
    margin-bottom: 80px;
}
.t-60{
    top:60px;
}
.t-20{
    top:20px;
}
.mt-20{
    margin-top:20px;
}
.mb-20{
    margin-bottom: 20px;
}
.fs-10{
    font-size: 10px;
}
.fs-14{
    font-size: 14px;
}
.fs-12{
    font-size: 12px;
}
.fs-35{
    font-size: 35px;
}
.fs-22{
    font-size: 22px;
}
.fs-16{
    font-size: 16px;
}
.fs-20{
    font-size: 20px;
}
.timeline-div{
    width: 30%;
    background-color: rgb(245, 245, 245);    
}
.delivery-timeline-section{
    position: relative;
    font-size: 80%;
    font-weight: 300;
    padding: 2px 0px;
    width: 95%;
    margin: 0px auto;
  
}
.timeline-bar{
    position: absolute;
    top: 25px;
    height: 80%;
    width: 2px;
    background: rgba(184, 190, 192,0.5);
    left: 16px;
}
.timeline-bar-ie{
    position: absolute;
    top: 25px;
    height: 85%;
    width: 2px;
    background: rgba(184, 190, 192,0.5);
    left: 16px;
}
.ant-timeline-item-tail {
    position: absolute;
    top: 32px;
    left: 16px;
    height: calc(100% - 27px);
    border-left: 2px solid #cccccc;
}
.h-350{
    height:350px;
}
.h-280{
    height:280px;
}
.timeline-message-body{
    position: relative;
    margin: 10px 0px;
    padding-left: 45px;
    text-align: left;
    min-height:70px;
}
.timeline-target{
    position: absolute;
    top: 0px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin-left: 2px;
    background: whitesmoke;
    border: 2px solid rgb(111, 186, 28);
    display: flex;
    color: rgb(111, 186, 28);
    left: 0px;
}
.timeline-target-circle{
    display: flex;
    width: 32px;
    height: 32px;
    position: relative;
    justify-content: center;
    cursor: pointer;
    align-self: center;
    align-items: center;
    color:black;
    font-weight: 400;
    font-size: small;
}
.timeline-content{
    position: relative;
    width:90%;
    margin-top: -3%;
  
}
.bg-white{
    background-color: rgb(255, 255, 255);
}
.timeline-content-body{
    max-width: 95%;
    min-width: 95%;
    width:-webkit-fill-available;
    box-shadow: 0px 1px 4px #cccccc;
    -webkit-box-shadow: 0px 1px 4px #cccccc;
    -moz-box-shadow: 0px 1px 4px #cccccc;
    margin-top: 1em;
    margin-bottom: 1em;
    line-height: 1.6;
    padding: 0.5rem;
    border-radius: 3px;   
}
.row-timeline {
    width: max-content;
    min-width:100%;
    display: flex;
}
.message-body{
    padding-left: 8px;
}
.timline-avatar-div{
    padding: 6px 0px 5px 4px;
}

.shopping-cart-avatar{
   color: limegreen;
    font-size: 25px;
}
.home-avatar{
    color:gray;
    font-size: 25px;
}

.lightgray{
    color:#cccccc;
}
.clearfix{
    clear: both;
    display: table;
}
.car-icon{

    font-size: 25px;
}
.timeline-content-sub{
    width: -webkit-fill-available;
    max-width: 91%;
    min-width: 91%;
    margin-left: 4%;   
    box-shadow: 0px 1px 4px #cccccc;
    -webkit-box-shadow: 0px 1px 4px #cccccc;
    -moz-box-shadow: 0px 1px 4px #cccccc;
    margin-top: 1em;
    margin-bottom: 1em;
    line-height: 1.6;
    padding: 0.5rem;
    border-radius: 3px;
}
.timeline-content-notify{    
    background-color: rgb(255, 255, 255);     
}
.timeline-content-inactive{   
    background-color: rgb(245, 245, 245);        
    color:lightgray;      
}
.avatar-inactive{
    color:#cccccc;
    font-size: 25px;    
}
.message-body b{   
    font-weight:500;
}
.message-body-notify{
    padding-left: 5px;
}
.message-body-notify b{
    font-size: 10px;
}
.message-body p{
    font-size: 10px;
}
.message-body small{  
  font-weight: 400;
}
.border-gray{
    border:2px solid gray;
}
.border-orange{
    border: 2px solid orange;
}
.notification-avatar{
    font-size: 25px;
    color: darkorange;
}
.order-content {
    width: -webkit-fill-available;
}
.order-details-div{
    margin-top:22px;
}
.header-title{
    line-height: 1.5rem;
}
.center{
    text-align: center;
}
hr {
    border: 0;
    width: 90%;
    border-top: 1px solid #eee;
}
.details-container {
    background-color: rgb(245, 245, 245);
    width: 75%;
    margin-left: 10%;
    padding: 15px 0px 20px 45px;
    display: flex;
    min-height: 280px;
}
.details-container-new {
    background-color: rgb(245, 245, 245);
    width: 75%;
    margin-left: 10%;
    padding:10px 140px 20px 25px;
    display: flex;
    min-height: 10px;
}
table tbody{
    width:100%;
    display:flex;
}
table tbody tr{
    width:100%;
    display:flex;
}
table tbody tr td{
    width:50%;
    display:block;
}
label{
    font-weight:700;
}
ul{
    list-style: none;
    padding-left: inherit;
    
}

.footer-div-1{
    padding:5px;
}
.footer-div-2{
    padding:5px;
}
.footer-div-3{
    padding: 5px;
    text-align: left;
    margin-left: auto;
}

.help-div {

    margin-bottom: 20px;
}

@media (max-width:425px) {
    .wrapper_row{
        padding-left: 3%;
        margin-top: 2px;
       
    }
    
    /* .wrapper_row .ant-col-6{
        flex: 0 0 45%;
        max-width: 45%;
    }
    .wrapper_row .ant-col-18{
        flex: 0 0 55%;
        max-width: 55%;
    } */
    .ant-col-12 {       
        max-width: 90%;
    }
    .timeline-bar{
        position: absolute;
        top: 10px;
        height: 73%;
        width: 1px;
        background: rgb(184, 190, 192,0.5);
        left: 5px;
    }
    
    .delivery-timeline-section {
        position: relative;
        font-size: 80%;
        font-weight: 300;
        padding: 3px 0px;
        width: 95%;
        margin: 0px auto;
    }
    .timeline-target {
        top: -5px;       
        margin-left: 0px;
        border: 3px solid rgb(111, 186, 28);
    }
    .message-body{
        padding-left: 15px;
    }
    .timeline-target-circle{       
        font-size: 12px;
    }
    .timeline-message-body {
        position: relative;
        margin: 15px 0px;
        padding-left: 12px;
        text-align: left;
        width: initial;
        font-size: 4px;
    }                
    .timeline-content {
        position: relative;
        width: 96%;
        top: 0px;
        left: 25px;
    }
    .timeline-content-body {
        border-radius: 3px;
        width:-webkit-fill-available;
        max-width: 96%;        
        box-shadow: rgb(0 0 0 / 10%) 0px 1px 3px 0px;
        margin-top: 1em;        
        line-height: 1.6;        
        border-radius: 3px;
        height: auto;
    }
    .timeline-content-sub{
        width: 84%;
        margin-left: 7%;       
        box-shadow: rgb(0 0 0 / 10%) 0px 1px 3px 0px;
        margin-top: 1em;
        margin-bottom: 1em;
        line-height: 1.6;
        padding: 0.5em 1em;
        border-radius: 3px;
        height: auto;
    }
    .timeline-content-notify {    
        background-color: rgb(255, 255, 255);       
    }
    .timeline-content-inactive {       
        background-color: rgb(245, 245, 245);        
        color: lightgray;        
    }
    .row-timeline {
        width: max-content;
        display: flex;
    }
    .timline-avatar-div {
        padding: 8px;
    }
    .shopping-cart-avatar {
        filter: invert(52%) sepia(82%) saturate(4082%) hue-rotate( 69deg) brightness(139%) contrast(85%);
        
    }
    
    .chat-icon {
        height: 12px;
    }
    .border-orange{
        border: 2px solid orange;
    }
    .border-gray{
        border:2px solid gray;
    }
    .message-body {
        margin-left: -5%;
        width: -webkit-fill-available;
    }
    .message-body-notify b{
        font-size: 4px;
    }
    .message-body small {
        font-size: 12px;
    }
    .header-title{
        font-size: 17px;
        line-height: 0.5rem;
    }
    .header-subtitle{
        font-size: 13px;
        font-weight: 400;
    }
    .order-content {
        margin-top: 10px;
    }
    .details-container {        
        margin-top: 0px;
        width: 100%;
        margin-left: 0%;       
        padding: 10px 0px 10px 25px;  
        text-align: center;   
        height: 100%;  
    }
    .order-details-view label,
    .column-details label{
        font-size:15px;                
    }
    .order-details-view p small{
        font-size: 13px; 
    }
    .column-details p{
        font-size: 15px;                
    }
    .message-body b {
        font-size: 15px;
    }
    .time-wrapper{
        margin-top: 10px;
        margin-bottom: 10px;        
    }
    .time-td{
        margin-top: 10px;
        padding-right: 20px;
        padding-left: 30px;
    }
    ul{
        margin-top: auto;
    }
    ul li a{
        font-size:6px;  
    }
    .delivery-status {
        border-radius: 3px;
    }
    
    .help-div p{
        font-size: 13px;
    }
    .help-title{
        font-size: 18px;
    }
    .footer{        
        display: flex;
        left: 1%;
        width: 94%;
        font-size: 10px;
        line-height: 0.5rem;
    }
    .footer small{
        font-size: 12px;
    }
    .ant-timeline-item-tail {
        top: 30px;
        left: 14px;
        height: calc(100% - 30px);
        border-left: 1px solid #cccccc;
    }
    .order-details-view p{
        margin-bottom: 15px;
        word-break:break-all;
    }
    .help-div {
        padding-left: 30px;
    }
    .delivery-status .p .time{
        font-size: 15px;
    }
    .timeline-div {        
        margin-top: 0px;
        height: auto;
    }    
}

/* android */
@media (max-width:1024px) and (min-width:768px){
    .wrapper_row{
        padding-left: 3%;        
    }
    .message-body{
        padding-left: 15px;
    }
    .wrapper_row .ant-col-6{
        flex: 0 0 40%;
        max-width: 40%;        
    }
    .wrapper_row .ant-col-18{
        flex: 0 0 60%;
        max-width: 60%;
    }
    .timeline-div {      
        margin-top: 0px;
        height: auto;
    }
    .details-container {
        margin-top: 0px;
        width: 98%;
        margin-left: 5%;
        padding: 20px 20px 30px;            
        min-height: 385px;   
    }
    .delivery-timeline-section{
        margin-top: 40px;
    }
    .ant-col-12 {
        display: block;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .message-body b {
        font-size: 15px;
        font-weight: 500;
    }
    .message-body small {
        font-size: 13px;
        font-weight: 400;
    }
    .order-details-view p{
        margin-bottom: 30px;
        word-break: break-all;
    }
    .time-wrapper{
        margin-bottom: 30px;
    }
    .help-title{
        font-size: large;
    }
    .order-details-view label{
        font-size: 16px;
    }
    .order-details-view p small{
        font-size: 14px;
    }
    .footer .ant-col-12{
        max-width: 50%;
    }
}

/* ipad */
@media (max-width:768px) and (min-width:425px){
    
    .message-body{
        padding-left: 15px;
    }
    .wrapper_row{
        padding-left: 3%;
    }
    .wrapper_row .ant-col-6{
        flex: 0 0 40%;
        max-width: 40%;
    }
    .wrapper_row .ant-col-18{
        flex: 0 0 60%;
        max-width: 60%;
    }
    .details-container {
        width: 100%;
        padding:15px 20px !important;
        margin-left: 0%;   
        margin-top:10px         
    }
    .ant-col-12 {
        display: block;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .timeline-div{
        height:auto;
    }
    .delivery-timeline-section {    
      padding: 5px 0px;   
    }
    .timeline-bar {
        height: 75%;
    }
    .timeline-message-body {    
      margin: 5px 0px;
      padding-left: 35px;    
    }
    .timeline-target {    
      width: 20px;
      height: 20px;        
      left: 5px;
    }
    .timeline-target-circle {
      
      font-size: 10px;
    }
    .timline-avatar-div {
      padding: 4px 0px 0px 0px;
    
    }
    .shopping-cart-avatar {    
        font-size: 16px;
    }
    .message-body b {
      font-size: 12px;    
    }
    .message-body small {
      font-size: 11px;    
    }
    .car-icon {       
      font-size: 15px;
    }
    .home-avatar {       
        font-size: 15px;
    }
    .notification-avatar {
        font-size: 15px;        
    }
    .avatar-inactive {       
        font-size: 15px;
    }
    .ant-timeline-item-tail {
        position: absolute;
        top: 25px;
        left: 16px;
        height: calc(100% - 27px);
        border-left: 2px solid #cccccc;
    }
    .order-details-view label{
        font-size: 16px;
    }
    .order-details-view p small{
        font-size: 14px;
    }
  }
  @media (max-width:768px) and (min-width:481px){
    .m-r-20{
        margin-right: 20%;
    }
  }